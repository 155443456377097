<template>
  <div class="goods-edit-draw">
    <page-draw @changedDrawer="changedDrawer" :isShow="pageDialogVisible">
      <div class="teacher-detail">
        <div class="top-info">
          <img class="avatar" v-if="staffDetail"
            :src="staffDetail.userPhoto" alt />
          <div class="teacher-name">{{ staffDetail && staffDetail.userRealname }}</div>
          <div class="shop">
            <span>所属店铺</span>
            <span v-if="staffDetail.shop" class="shop-name">
              <template :key="item" v-for="item in staffDetail.shop">
                <span>{{ item.shopName }}</span>
              </template>
            </span>
          </div>
        </div>

        <div class="base-info">
          <div class="title">
            <div>基本信息</div>
            <div class="other">
              <span @click="handleResetPasswordClick">重置密码</span>
              <span @click="handleEditClick" v-has="'staff:edit'">编辑</span>
            </div>
          </div>
          <div class="base-info-list">
            <div class="info-item">
              <div class="item-name">系统账号:</div>
              <div v-if="staffDetail" class="value">{{ staffDetail.userMobile }}</div>
            </div>
            <div class="info-item">
              <div class="item-name">岗位:</div>
              <div v-if="staffDetail" class="value">{{ staffDetail.jobLevelName }}</div>
            </div>
            <div class="info-item">
              <div class="item-name">职级:</div>
              <div v-if="staffDetail" class="value">{{ staffDetail.subJobLevelName }}</div>
            </div>
            <div class="info-item">
              <div class="item-name">部门:</div>
              <div v-if="staffDetail" class="value">{{ staffDetail.dpName }}</div>
            </div>
            <div class="info-item">
              <div class="item-name">入职时间:</div>
              <div v-if="staffDetail" class="value">{{ staffDetail.joinTime }}</div>
            </div>
            <div class="info-item">
              <div class="item-name">个人介绍:</div>
              <div v-if="staffDetail" class="value">{{ staffDetail.userPersonInfo }}</div>
            </div>
          </div>
        </div>

        <div class="serve" v-if="show">
          <div class="title">
            <div>关联服务</div>
            <!-- <div @click="handleAssociateClick" class="other">关联</div> -->
          </div>
          <div class="serve-list">
            <template :key="item" v-for="item in staffServeBindList">
              <div class="serve-item">
                <div>{{ item.serveName }}</div>
                <!-- <div @click="handleRemoveAssociationClick(item)" class="other">移除</div> -->
              </div>
            </template>
          </div>
        </div>
        <div class="serve" v-if="staffDetail.certificate">
          <div class="title">
            <div>资质证书</div>
            <!-- <div @click="handleAssociateClick" class="other">关联</div> -->
          </div>
          <div class="serve-list certificate" v-html="staffDetail.certificate">
          </div>
        </div>
        
      </div>
    </page-draw>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { categoryProps } from './config'
import { useRouter } from 'vue-router'
import emitter from '@/utils/eventbus'
import { setStaffServeBind, removeStaffServeBind } from '@/service/main/staff'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    staffId: {
      type: Number,
      default: 1
    },
    isChange: {
      type: Boolean,
      default: true
    },
    show:{
      type: Boolean,
      default: true
    }
  },
  components: {
    PageDraw
  },
  emits: ['changedDrawer', 'handleResetPasswordClick', 'handleAssociateClick', 'handleStaffEditClick'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const pageDialogVisible = computed(() => props.dialogVisible)
    const staffServeBindList = computed(() => store.state.staff.staffServeBindList)

    const changedDrawer = (flag) => {
      emit('changedDrawer', flag)
    }

    const currentGoodsTag = ref({})

    const initPage = () => {
      store.dispatch('staff/getStaffServeBindListAction', props.staffId)
      store.dispatch('staff/getStaffDetailAction', [props.staffId, COMPANY_ID])
    }

    // watch(() => props.isChange, () => {
    //   store.dispatch('staff/getStaffDetailAction', [props.staffId, COMPANY_ID])
    // }, {
    //   deep: true
    // })

    const formState = useState('goods', [])

    watch(() => props.staffId, () => {
      initPage()
    }, {
      deep: true,
      immediate: true
    })

    const staffDetail = computed(() => store.state.staff.staffDetail)
    const handleResetPasswordClick = () => {
      emit('handleResetPasswordClick')
    }

    const handleRemoveAssociationClick = async (item) => {
      const res = await removeStaffServeBind(item.serveId, props.staffId, COMPANY_ID, item.paraId)
      if (res.code == 0) {
        store.dispatch('staff/getStaffServeBindListAction', props.staffId)
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
      }
    }

    const handleEditClick = () => {
      emit('handleStaffEditClick')
    }

    const handleOperateClick = (type) => {
    }

    const handleAssociateClick = () => {
      emit('handleAssociateClick')
    }

    return {
      handleResetPasswordClick,
      pageDialogVisible,
      changedDrawer,
      UPLOAD_BASE_URL,
      ...formState,
      currentGoodsTag,
      categoryProps,
      handleEditClick,
      handleOperateClick,
      staffDetail,
      handleAssociateClick,
      staffServeBindList,
      handleRemoveAssociationClick

    }

  }
})
</script>

<style scoped lang="less">
.goods-edit-draw {
  font-size: 12px;

  .teacher-detail {
    .top-info {
      height: 186px;
      background-color: rgba(231, 189, 137, 1);
      font-size: 12px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .teacher-name {
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        margin-top: 6px;
        margin-bottom: 20px;
      }

      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .shop {
        width: 192px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(153, 101, 34, 1);
        border-radius: 16px;
        font-size: 12px;

        .shop-name {}
      }
    }

    .base-info {
      margin-top: 22px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;

      .title {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding-left: 16px;
        color: rgba(80, 80, 80, 1);
        font-size: 12px;

        .other {
          color: rgba(42, 130, 228, 1);
          cursor: pointer;

          span {
            margin: 0 6px;
            cursor: pointer;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 20px;
          background-color: rgba(42, 130, 228, 1);
          left: 4px;
          top: -4px;
        }
      }

      .base-info-list {
        margin-top: 30px;


        .info-item {
          display: flex;
          margin-bottom: 12px;

          .item-name {
            width: 100px;
            text-align: right;
            color: rgba(166, 166, 166, 1);
            font-size: 12px;
            margin-right: 26px;
          }

          .value {
            color: rgba(80, 80, 80, 1);
            font-size: 12px;
            width: 300px;
          }
        }
      }
    }

    .serve {
      margin-top: 22px;

      .title {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-left: 16px;
        color: rgba(80, 80, 80, 1);
        font-size: 12px;

        .other {
          color: rgba(42, 130, 228, 1);
          cursor: pointer;
          span {
            margin: 0 4px;
            cursor: pointer;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 20px;
          background-color: rgba(42, 130, 228, 1);
          left: 4px;
          top: -4px;
        }
      }

      .serve-list {
        padding-left: 20px;

        .serve-item {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
          color: rgba(80, 80, 80, 1);
          font-size: 12px;

          .other {
            color: rgba(42, 130, 228, 1);
            cursor: pointer;
          }
        }
      }
      .certificate{
        margin-top: 20px;
        padding: 0 20px;
      }
    }

    .reset-password-dialog {
      .reset-password-dialog-ruleForm {
        .tips {
          color: rgba(166, 166, 166, 1);
          font-size: 12px;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>




